<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增 </el-button>
        <el-button type="danger" size="small" :disabled="!btn.delete" @click="doDelete()"><i class="el-icon-delete"></i> 删除</el-button>
        <el-button class="vd_export" size="small" type="info" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新</el-button>
        <el-button type="success" size="small" @click="syncInstDialogVisible = true" icon="el-icon-refresh">同步</el-button>
      </div>
      <search-table
        ref="multiTable"
        :data="tableData"
        :tableRowKey="`inst_id`"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        @selection-change="handleSelectionChange"
        @getTableData="getInstsList"
        @row-dblclick="dbClickJp"
      >
        <template v-slot:status="scope">
          <div style="text-align: center">
            <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
              {{ helper.getStatusName(scope.row.status).name }}
            </el-tag>
          </div>
        </template>
      </search-table>
      <el-dialog title="同步机构信息" width="70%" top="0" :visible.sync="syncInstDialogVisible" @opened="getSyncInstData">
        <search-table
          ref="syncInstTable"
          :tableRowKey="`autoInc`"
          :data="syncInstTableData"
          :totalPage="syncInstTotalPage"
          :columns="syncInstTableProperties"
          v-loading="syncLoadFlag"
          @row-dblclick="syncInstData"
          @getTableData="getSyncInstData"
          :need-fixed-height="true"
        />
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { getNoCatch, postNoCatch } from '@api/request';
import { instAPI } from '@api/modules/inst';
import { optnAPI } from '@api/modules/optn';
import pubPagination from '@/components/common/pubPagination';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { syncInstTableProperties, tableProperties } from '@/views/MessageManagement/InstManage/inst';
import { getArrayIds } from '@assets/js/arrayUtils';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'instList',
  components: {
    SearchTable,
    pubPagination
  },
  data() {
    return {
      syncInstDialogVisible: false,
      syncInstTableData: [],
      syncInstTableProperties: syncInstTableProperties,
      syncLoadFlag: true,
      syncInstTotalPage: 0,
      tableProperties: tableProperties,
      tableData: [],
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      currentPage: 1,
      instTypes: [],
      stffUserList: []
    };
  },
  mounted() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/inst_add' || from.path === '/inst_edit') {
        this.initData();
      }
    }
  },
  methods: {
    initData() {
      this.getInstsList();
      this.getInstAttrList();
    },
    getInstsList() {
      this.loadFlag = true;
      getNoCatch(instAPI.getInsts, this.$refs.multiTable.searchForm).then(({ data }) => {
        this.loading = false;
        this.tableData = data.list;
        this.totalPage = data.total;
        this.btn = data.btn;
        this.loadFlag = false;
      });
    },
    // 获取机构类别
    getInstAttrList() {
      getNoCatch(optnAPI.getOptnByPermId, { perm_id: 10012 }).then(({ data }) => {
        this.instTypes = data.form.optn_cntt_list;
        let options = Array.from(this.instTypes, ({ param1 }) => {
          return { value: param1, label: param1 };
        });
        this.tableProperties[1].options = options;
        this.syncInstTableProperties[3].options = options;
      });
    },
    // 刷新
    buttonRefresh() {
      this.$refs.multiTable.resetFields();
      this.loadFlag = true;
      this.getInstsList();
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 判断是否可以删除
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      this.jump('/inst_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: this.$route.query.perm_id,
            form_id: row.inst_id
          })
        )
      });
    },
    // //新增
    addRow() {
      const permId = this.$route.query.perm_id;
      const personal = this.$cookies.get('userInfo').personal;
      this.jump(`/inst_add`, { perm_id: permId, personal: personal });
    },
    // 未选中提示
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        let temp = false;
        for (let i = 0; i < this.multiSelection.length; i++) {
          if (this.multiSelection[i].status === 2) {
            temp = true;
            break;
          }
        }
        if (temp) {
          this.$message({ type: 'error', message: '此单据已生效或者正在审批中,无法删除！' });
          this.$refs.multiTable.clearSelection();
        } else {
          this.mBox();
        }
      }
    },
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          postNoCatch(instAPI.deleteInstByIds, { inst_id_list: getArrayIds(this.multiSelection, 'inst_id') }).then(({ data }) => {
            this.$message.success('删除成功!');
            this.initData();
          });
        })
        .catch(() => {
          this.$message.info('取消删除!');
        });
    },
    syncInstData(row) {
      this.syncInstDialogVisible = false;
      this.$router.push({
        name: 'instAdd',
        query: { perm_id: this.$route.query.perm_id, autoInc: row.autoInc },
        params: { syncInstData: row }
      });
    },
    async getSyncInstData() {
      this.syncLoadFlag = true;
      await instAPI.getRichErpInst(this.$refs.syncInstTable.searchForm).then(({ data }) => {
        this.syncInstTableData = data.list;
        this.syncInstTotalPage = data.total;
        this.syncLoadFlag = false;
      });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
