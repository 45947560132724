import { getDateNoTime } from '@assets/js/dateUtils';

export const tableProperties = [
  { prop: 'selection', type: 'selection', labelWidth: '48px' },
  { prop: 'inst_type', label: '机构类别', itemType: 'select', options: [], widthAuto: true },
  { prop: 'inst_no', label: '机构编号', itemType: 'input', widthAuto: true },
  { prop: 'inst_abbr', label: '机构简称', itemType: 'input', widthAuto: true },
  { prop: 'inst_cname', label: '机构中文', itemType: 'input', widthAuto: true },
  { prop: 'inst_ename', label: '机构英文', itemType: 'input', widthAuto: true },
  { prop: 'inst_city', label: '所在城市', input: false, itemType: 'input', widthAuto: true },
  { prop: 'inst_contact', label: '负责人', itemType: 'input', widthAuto: true },
  { prop: 'inst_cmail', label: '负责人邮箱', input: false, itemType: 'input', widthAuto: true },
  { prop: 'inst_biller', label: '开票人', itemType: 'input', widthAuto: true },
  { prop: 'inst_bmail', label: '开票人邮箱', input: false, itemType: 'input', widthAuto: true },
  { prop: 'stff_name', label: '录入人', itemType: 'input', widthAuto: true },
  { prop: 'create_time', label: '录入时间', itemType: 'date', widthAuto: true, formatter: val => getDateNoTime(val, true) },
  {
    prop: 'status',
    label: '单据状态',
    itemType: 'select',
    fixed: 'right',
    options: [
      { value: 0, label: '草拟' },
      { value: 1, label: '在批' },
      { value: 2, label: '生效' }
    ]
  }
];
export const syncInstTableProperties = [
  { prop: 'selection', type: 'selection', labelWidth: '48px' },
  { prop: 'inst_abbr', label: '机构简称', itemType: 'input', input: true, widthAuto: true },
  { prop: 'inst_cname', label: '机构中文', itemType: 'input', input: true, widthAuto: true },
  { prop: 'inst_type', label: '机构类别', itemType: 'input', input: false, widthAuto: true }
];
